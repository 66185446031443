import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ServerService } from '../services/server.service';
declare var $: any;

@Component({
  selector: 'app-liesten-dashboard',
  templateUrl: './liesten-dashboard.component.html',
  styleUrls: ['./liesten-dashboard.component.css']
})
export class LiestenDashboardComponent implements OnInit {
  recordNotFound;
  liesten_data;
  websocket;
  hardware_id;
  liesten_dashboard;
  constructor(private serverService: ServerService) { }

  ngOnInit(): void {
    //this.get_liesten();
    this.hardware_id = localStorage.getItem('hardware_id');
    
    this.insocket();
    
    
    
  }
  insocket(){
    var self = this;
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001");
    // this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001");
    this.websocket.onopen = function (event) {
      $('#listensendonload').click();
      console.log('agent socket connected');
    }
    this.websocket.onmessage = function (event) {
      console.log(event.data);
     var result_message = JSON.parse(event.data);
     console.log(result_message[0].data[0])
     
    //  var k = '[{"cust_id":"BF20-79D9-3A87-97B4-82FF-1225-825A-9951","data":[{"db_agent":"sj-105","db_supvisor":"test-101"},{"db_agent":"sj test-106","db_supvisor":""}]}]';
    //  var result_message = JSON.parse(k);
    //  if(result_message[0].data[0].reload_supervisor){
    //   self.get_liesten(); 
    // }else{

    // }
    
    if(result_message[0].data[0].db_agent){
      self.liesten_dashboard = result_message[0].data;
      console.log(self.liesten_dashboard)
    }
    }
    this.websocket.onerror = function (event) {
      Swal.close();

      console.log('error');
    }
    this.websocket.onclose = function (event) {
      Swal.close();

      console.log('close');
    }


  }
  dashboard_reload(){
    var socket_message = '[{"cust_id":"'+this.hardware_id+'","data":[{"Name":"dashboard_reload"}]}]';
    console.log(socket_message)
    this.websocket.send(socket_message);
  }
  get_liesten(){
  let api_req:any = '{"operation":"agents","moduleType":"agents","api_type":"web","access_token":"'+localStorage.getItem('access_Token')+'","element_data":{"admin_id":"'+localStorage.getItem('admin_id')+'","action":"coach_dashboard"}}';
this.serverService.sendServer(api_req).subscribe((response:any)=>{
if(response.result.status==true){
  if(response.result.data!=''){
    this.liesten_data = response.result.data;
    console.log(this.liesten_data)
    this.recordNotFound=false;
  }else{
    this.recordNotFound=true;
  }
}
})
  }
}
