import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  hardware_id: string;
  license_key: any;
  exp: any;

  constructor(private serverService: ServerService) { }

  ngOnInit(): void {
    this.hardware_id = localStorage.getItem('hardware_id');
    if(this.hardware_id == '' || this.hardware_id == null || this.hardware_id == undefined){
      iziToast.warning({
        message: "Product Is Empty",
        position: 'topRight'
    });
    }
    this.get_porduct();
  }
get_porduct(){
  let api_req :any = '{"access_token":"MjIzOCMjIzhkYzEwZGFkYjExYjdjOGNjMTgy","operation":"curlDatas","moduleType":"login","api_type":"web","element_data":{"action":"get_mrvoip_hardware_details","hardware_id":"'+this.hardware_id+'"}}'
  this.serverService.sendServererp(api_req).subscribe((response:any) => {
    if(response.license_key != '' || response.license_key != null){
      this.license_key = response.license_key;
      this.exp = response.exp_date;
    } else {
      iziToast.warning({
        message: "Your License Key Is Empty",
        position: 'topRight'
    });
    }
  }, 
  (error)=>{
      console.log(error);
  });
}
}
